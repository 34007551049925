import SmoothScroll from 'smooth-scroll';
import '../scss/main.scss';
import './icons';
import './videoModal';

new SmoothScroll('a[href*="#"]', {
  speed: 300,
  offset: 50,
  header: '#header',
});
