import fontawesome from '@fortawesome/fontawesome';
import faPlay from '@fortawesome/fontawesome-pro-solid/faPlay';
import faTimes from '@fortawesome/fontawesome-pro-solid/faTimes';
import faPaperPlane from '@fortawesome/fontawesome-pro-solid/faPaperPlane';
import faClipboardCheck from '@fortawesome/fontawesome-pro-solid/faClipboardCheck';
import faChevronRight from '@fortawesome/fontawesome-pro-solid/faChevronRight';
import { faLinkedin } from '@fortawesome/fontawesome-free-brands/faLinkedin';

fontawesome.library.add(
  faTimes,
  faPlay,
  faPaperPlane,
  faClipboardCheck,
  faChevronRight,
  faLinkedin
);
