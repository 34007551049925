getModalButton().addEventListener('click', openModal);
getModal().addEventListener('click', closeModal);

function openModal(event) {
  event.preventDefault();
  getVideoContainer().innerHTML = getVideo();
  getModal().classList.remove('is-hidden');
}

function closeModal(event) {
  if (
    event.target === getVideoContainer() ||
    event.target.closest('[data-video-overlay-video]')
  ) {
    return;
  }

  getModal().classList.add('is-hidden');
  getVideoContainer().innerHTML = '';
}

function getModalButton() {
  return document.querySelector('[data-video-overlay-button]');
}

function getModal() {
  return document.querySelector('[data-video-overlay]');
}

function getVideoContainer() {
  return document.querySelector('[data-video-overlay-video]');
}

function getVideo() {
  return `
    <iframe
      src="https://www.youtube.com/embed/${getVideoId()}?autoplay=1"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  `;
}

function getVideoId() {
  return getVideoContainer().getAttribute('data-video-overlay-video');
}
